import { MemberLevelType } from "@/interfaces/memberLevel";
import { i18n } from "@/plugins/i18n";
import {
  FormField,
  FormFieldTypes,
  TableFieldType,
  TableHeader
} from "helix-vue-components";

export const MemberLevelTableMetadata: TableHeader[] = [
  {
    value: "name",
    label: i18n.t("member_level_pricing.name").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "code",
    label: i18n.t("member_level_pricing.code").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "type",
    label: i18n.t("member_level_pricing.type").toString(),
    fieldComponent: TableFieldType.enumText,
    enum: {
      [MemberLevelType.PRICE_POINT]: i18n.t("price_points").toString(),
      [MemberLevelType.PERCENTAGE]: i18n.t("percentage").toString()
    },
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "rate",
    label: i18n.t("member_level_pricing.discount_rate").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "automatic_percentage_fallback",
    label: i18n.t("member_level_pricing.fallback").toString(),
    fieldComponent: TableFieldType.bool,
    class: "tdt__headers__fieldLong",
    sortable: true
  }
];

export const MemberLevelFormMetadata: FormField[] = [
  {
    component: FormFieldTypes.textField,
    label: i18n.t("member_level_pricing.name"),
    name: "name",
    options: {
      validationRules: ["required"],
      placeholder: i18n.t("name"),
      row: 1,
      flex: 4
    }
  },
  {
    component: FormFieldTypes.select,
    label: i18n.t("member_level_pricing.type"),
    name: "type",
    options: {
      placeholder: i18n.t("select_type"),
      associationText: "text",
      associationValue: "value",
      selectOptions: () => [
        {
          value: MemberLevelType.PRICE_POINT,
          text: i18n.t("price_points").toString()
        },
        {
          value: MemberLevelType.PERCENTAGE,
          text: i18n.t("percentage").toString()
        }
      ],
      row: 1,
      flex: 4
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("member_level_pricing.rate"),
    name: "rate",
    options: {
      placeholder: i18n.t("rate"),
      row: 1,
      flex: 4
    }
  }
];
