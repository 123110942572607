import {
  defaultMemberLevel,
  MemberLevel,
  MemberLevelType
} from "@/interfaces/memberLevel";
import { PusherNotification } from "@/interfaces/notification";
import { pusherEvents } from "@/internal";
import { MemberLevelFormMetadata } from "@/metadata/memberLevel";
import { Callback, PageNavAction } from "@/types/types";
import {
  DynamicFormComponent,
  FormField,
  FormResponse
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./MemberLevelPricingForm.template.vue";

const namespace = "MemberLevelModule";

@Component({
  mixins: [Template],
  inject: ["$changes"],
  components: {
    "helix-form": DynamicFormComponent
  }
})
export default class MemberLevelPricingFormComponent extends Vue {
  @Getter("memberLevel", { namespace })
  public current!: MemberLevel;
  @Action("findMemberLevel", { namespace })
  public findMemberLevel!: Callback;
  @Action("saveMemberLevel", { namespace })
  public saveMemberLevel!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public fieldsConfig: FormField[] = MemberLevelFormMetadata;
  public form!: DynamicFormComponent;

  public isSaving = false;
  public isLoading = false;

  protected model: MemberLevel = { ...defaultMemberLevel };

  public async save() {
    const resp: FormResponse = await this.form.submit();
    if (resp.valid) {
      this.isSaving = true;
      await this.saveMemberLevel(resp.currentModel);
      this.isSaving = false;
    }
  }

  public back() {
    this.$router.push({ name: "member-level-list" });
  }

  public async onChange(data: { model: MemberLevel; modelField: string }) {
    this.model = data.model;
  }
  public created() {
    this.fieldsConfig.map(fields => {
      if (this.$route.name === "member-level-view") {
        fields.options!.disableCondition = () => true;
      } else {
        fields.options!.disableCondition = () => false;
      }
    });
  }
  protected async mounted() {
    this.setPageNav({
      title: `member_level_pricing.${
        this.$route.params.id ? "" : "new_"
      }member_level`,
      isLoading: () => this.isSaving,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            id: "btn_save",
            action: this.save,
            vuetifyProps: () => ({
              loading: this.isSaving,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            id: "btn_cancel",
            action: this.back,
            vuetifyProps: () => ({
              loading: this.isSaving || this.isLoading,
              disabled: this.isSaving,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
    this.form = this.$refs["member-level-form"] as DynamicFormComponent;
    if (this.$route.params.id && this.model.id === null) {
      this.loadMemberLevel();
    }
    this.$changes.watch(
      pusherEvents.memberLevelTouched,
      this.loadMemberLevel,
      (data: PusherNotification) => {
        return !!data.message.find(n => n.item_id === +this.$route.params.id);
      }
    );
    setTimeout(() => {
      this.fieldsConfig[3].options!.visibleCondition = () => {
        return this.model.type === MemberLevelType.PRICE_POINT;
      };
    }, 500);
  }

  protected async loadMemberLevel() {
    this.isLoading = true;
    await this.findMemberLevel(this.$route.params.id);
    this.isLoading = false;
    this.model = { ...this.current };
  }
}
