export interface MemberLevel {
  id: number | null;
  name: string;
  account_id?: number;
  code: string | null;
  created_at?: string;
  is_active?: boolean;
  type?: MemberLevelType | null;
  automatic_percentage_fallback?: boolean;
  rate?: string;
}

export const defaultMemberLevel: MemberLevel = {
  id: null,
  name: "",
  code: "",
  type: null,
  is_active: true,
  automatic_percentage_fallback: false
};

export enum MemberLevelType {
  PERCENTAGE = "PERCENTAGE",
  PRICE_POINT = "PRICE_POINT"
}
